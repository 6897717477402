import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Grid from "../elements/grid"
import { Body, Canon } from "../elements/typography"
import Icon from "../elements/icon"

import linkIcon from "../../theme/icons/link.svg"
import PhExternalLink from "../elements/externalLink"

const DigitalBankingDoneRightSection = () => (
  <Grid.FullWidthSection styles={{
    position: `relative`,
    padding: `4rem 0`,
    alignItems: `center`,
    overflowX: `hidden`,
    zIndex: `8`,
  }}>
  <Grid.Block start={[2,2,9,10,9,9]} span={[12,0,7,5,5,4]} >
      <StaticImage 
        css={{
          display: `block !important`,  
          '@media (min-width: 736px)': { display: 'none !important' }, 
          '@media (min-width: 1024px)': { display: 'block !important' } 
        }} 
        src="../../images/products/digital-banking/tcb-digital-banking-02_full.png" 
        alt="TCB NuFund Online Account Opening" 
      />
      <StaticImage 
        css={{
          display: `none !important`,  
          '@media (min-width: 736px)': { display: 'block !important' }, 
          '@media (min-width: 1024px)': { display: 'none !important' } 
        }} 
        src="../../images/products/digital-banking/tcb-digital-banking-02_cropped.png" 
        alt="TCB NuFund Online Account Opening" 
      />
    </Grid.Block>
    <Grid.Block start={[2,2,2,2,2,3]} span={[12,12,7,8,6,5]} css={{
      marginBottom: `2rem`,
      gridRowStart: `1`,
      '@media (min-width: 1495px)': {
        marginBottom: `0`
      }
    }}> 
      <Canon>Digital Banking Done Right</Canon>
      <Body css={{textAlign: `justify`}}>
        Our digital banking app puts control at your fingertips. You can use simple, smart tools like instantly freezing and unfreezing your debit card with the tap of a button, activate new cards, 
        request temporary limit increases, set travel alerts, and change your pin, wherever you are. Add your debit card to your mobile wallet and pay with your phone. Send money to anyone instantly 
        via text message or email address, manage your bills, and deposit checks. Best of all, digital banking has security built-in, with 128-bit encryption, fraud anomaly detection, and biometric 
        authentication.  Our app is just another way we make banking simple. Download today to take control of your finances in the palm of your hand. That is digital banking done right.   
      </Body>
      <div css={{marginTop: `2rem`}}>
                          <PhExternalLink to="https://apps.apple.com/us/app/the-claxton-bank-mobile/id994317983?ign-mpt=uo%3D4">
                <StaticImage src="../images/products/digital-banking/app-store-badge.png" alt="Download on the App Store" height="50" width="168" />
              </PhExternalLink>
              <PhExternalLink to="https://play.google.com/store/apps/details?id=com.csiweb.digitalbanking.bk0704"><StaticImage src="../images/products/digital-banking/google-play-badge.png" alt="Get it on Google Play" height="50" width="168"/></PhExternalLink><br />
        <Link css={{display: `inline-block`, marginTop: `2rem`, textDecoration: `none` }} to="/personal/digital-banking">Learn More About Digital Banking &nbsp; <Icon icon={linkIcon} overrides={{verticalAlign: `0em !important`}}/></Link>
      </div>
    </Grid.Block>
  </Grid.FullWidthSection>
)

export default DigitalBankingDoneRightSection